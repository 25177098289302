/* src/styles/global.css */

/* Root Variables for Colors and Fonts */
:root {
  /* Color Variables */
  --primary-color: #4b0082;
  --secondary-color: #6a0dad;
  --accent-color: #8a2be2;
  --text-color: #333;
  --background-color: #f9f9f9;
  --white: #fff;
  --error-color: #d9534f;

  /* Font Variables */
  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Roboto", sans-serif;
}

/* Global Styles */
body {
  margin: 0;
  font-family: var(--font-secondary);
  color: var(--text-color);
  background-color: var(--background-color);
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/* Apply box-sizing to all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Typography Adjustments */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  margin: 0;
}

/* Link Styles */
a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

/* Global Focus Styles for Accessibility */
:focus {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

/* Button Styles */
button {
  font-family: var(--font-secondary);
  padding: 0.6rem 1.2rem;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

button:hover,
button:focus {
  background-color: var(--secondary-color);
}

button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 0.75rem;
  text-align: left;
}

table th {
  background-color: var(--primary-color);
  color: var(--white);
}

/* Code and Preformatted Text Styles */
pre,
code {
  font-family: "Courier New", Courier, monospace;
  background-color: #eee;
  padding: 1rem;
  border-radius: 6px;
  overflow: auto;
}

/* Form Input Styles */
input,
textarea,
select {
  font-family: var(--font-secondary);
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus,
textarea:focus,
select:focus {
  border-color: var(--accent-color);
  outline: none;
}

/* Error Message Styles */
.error-message {
  color: var(--error-color);
  font-size: 0.9rem;
}

/* Accessibility Helper Classes */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Clearfix Utility */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
