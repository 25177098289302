/* frontend/src/components/NavBar.css */

/* Navbar Styles */
nav.navbar {
  background-color: var(--white);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav.navbar .logo-container {
  display: flex;
  align-items: center;
}

nav.navbar .logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

nav.navbar .logo-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 0.4rem;
  object-fit: cover;
}

nav.navbar .logo-text {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: var(--font-primary);
}

nav.navbar .nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

nav.navbar .nav-link {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  font-family: var(--font-primary);
}

/* Simple underline effect on hover */
nav.navbar .nav-link::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: var(--secondary-color);
  transition: width 0.3s;
  position: absolute;
  bottom: -3px;
  left: 0;
}

nav.navbar .nav-link:hover::after {
  width: 100%;
}

/* Cart icon size */
nav.navbar .cart-icon {
  width: 24px;
  height: 24px;
}

/* Special styling for register & logout buttons */
nav.navbar .nav-link.highlight {
  background-color: var(--secondary-color);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

nav.navbar .nav-link.highlight:hover {
  background-color: var(--accent-color);
  color: var(--white); /* Ensure text remains white on hover */
}

/* Mobile Menu Icon */
nav.navbar .mobile-menu-icon {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

nav.navbar .hamburger,
nav.navbar .hamburger::before,
nav.navbar .hamburger::after {
  content: "";
  display: block;
  width: 25px;
  height: 3px;
  background-color: var(--primary-color);
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

nav.navbar .hamburger::before,
nav.navbar .hamburger::after {
  position: relative;
}

nav.navbar .hamburger::before {
  top: -8px;
}

nav.navbar .hamburger::after {
  top: 5px;
}

/* Active State for Mobile Menu Icon */
nav.navbar .mobile-menu-icon.active .hamburger {
  background-color: transparent;
}

nav.navbar .mobile-menu-icon.active .hamburger::before {
  transform: rotate(45deg) translate(5px, 5px);
}

nav.navbar .mobile-menu-icon.active .hamburger::after {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  nav.navbar {
    position: relative;
    justify-content: space-between;
  }

  nav.navbar .nav-links {
    display: none;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--white);
    padding: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 1000;
  }

  nav.navbar .nav-links.active {
    display: flex;
  }

  nav.navbar .mobile-menu-icon {
    display: block;
  }

  nav.navbar .logo-text {
    font-size: 1.4rem;
  }
}

/* Accessibility Focus Styles */
nav.navbar .nav-link:focus,
nav.navbar .logo-link:focus,
nav.navbar .mobile-menu-icon:focus {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

/* Ensure NavBar is not affected by other styles */
nav.navbar,
nav.navbar * {
  box-sizing: border-box;
}

/* Prevent external styles from affecting the NavBar */
nav.navbar img {
  max-width: none;
}
