/* frontend/src/components/ErrorBoundary.css */

.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  padding: 1rem;
}

.error-boundary h1 {
  font-size: 2.5rem;
  color: var(--error-color);
  margin-bottom: 1rem;
}

.error-boundary p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

.error-boundary button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.error-boundary button:hover,
.error-boundary button:focus {
  background-color: var(--secondary-color);
  outline: none;
}
